<template>
  <div>
    <div class="super">
      <div class="holder">
        <Header />
        <!-- Hero section starts -->
        <div class="hero-section">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-6">
                <div class="hero-left">
                  <h1>They Stay, We Pay.</h1>
                  <p>
                    Shelta takes away the burdens of staff who have to save for
                    rent from salary. Work with us to provide your staff with a
                    convenient and a less challenging way to pay rent.
                  </p>
                  <div class="create-btn">
                    <button class="btn">
                      <router-link to="/signup">
                        Create a free account
                      </router-link>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- First Hero section ends -->

        <!-- Cards start -->
        <div class="cards-section">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-4">
                <div class="card-holder">
                  <img src="../assets/tenant/rpicon.svg" alt="rent-payment" />
                  <h4>Flexible Rent Payment</h4>
                  <p>
                    Staff can choose the repayment term that suits them best,
                    whether it's daily, weekly or monthly, and spread the
                    payments over time.
                  </p>
                </div>
              </div>
              <div class="col-md-4">
                <div class="card-holder">
                  <img src="../assets/tenant/fmicon.svg" alt="fm" />
                  <h4>Competitive Interest Rates</h4>
                  <p>
                    Property financing with Shelta comes with lower interest
                    rates than other types of financing, making it a more
                    affordable way to buy a home.
                  </p>
                </div>
              </div>
              <div class="col-md-4">
                <div class="card-holder">
                  <img src="../assets/tenant/lcicon.svg" alt="lc" />
                  <h4>Save Time And Energy</h4>
                  <p>
                    House hunting can be stressful, tiring and frustrating. Even
                    when you have your cash, getting a property you want in the
                    right location can be quite challenging. By subscribing to
                    Shelta, you get exclusive access to unlimited properties
                    right from your screen.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Cards ends -->

        <HomePart />
      </div>
      <FooterMain />
    </div>
  </div>
</template>

<script>
import FooterMain from "../views/FooterMain";
import Header from "../views/Header";
import HomePart from "./HomePart.vue";
import { Service } from "../store/service";
import $ from "jquery";
// const Api = new Service();

export default {
  name: "Organization",
  components: {
    Header,
    FooterMain,
    HomePart
  },
  data() {
    return {
      theClient: "landlord"
    };
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
$sheltaBlack: #000a2f;
$primary: #0033ea;
$tertiary: #a2b0e3;
$whiteText: #ffffff;
$yellow: #ffb100;
$listing-bg: #64abb2;
$offGray: #555c74;
$bg: #f9f9ff;

@font-face {
  font-family: "Lato-Bold";
  src: local("Lato-Bold"),
    url("../assets/fonts/Lato/Lato-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Lato";
  src: local("Lato-Regular"),
    url("../assets/fonts/Lato/Lato-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Lato-light";
  src: local("Lato-Light"),
    url("../assets/fonts/Lato/Lato-Light.ttf") format("truetype");
}
@font-face {
  font-family: "Lato-bold-Italic";
  src: local("Lato-BlackItalic"),
    url("../assets/fonts/Lato/Lato-BlackItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Lato-thin-Italic";
  src: local("Lato-ThinItalic"),
    url("../assets/fonts/Lato/Lato-ThinItalic.ttf") format("truetype");
}

a {
  color: inherit;
}
a:hover {
  color: inherit;
  text-decoration: inherit;
}
.super {
  flex-direction: column;
  display: flex;
  min-height: 100vh;
  position: relative;
}
.holder {
  flex-grow: 1;
  min-height: 100%;
  position: relative;
  // background: $whiteText;
}

// Hero section start
.hero-section {
  padding-left: 77px;
  padding-left: 0px;
  padding-top: 0;
  margin-top: 7rem;
  height: 785px;
  // height: 100%;
  padding: 0 10px;
  background: linear-gradient(
      89.84deg,
      rgba(0, 0, 0, 0.15) 58.79%,
      rgba(0, 0, 0, 0.0495) 71.43%,
      rgba(255, 255, 255, 0) 95.57%
    ),
    url("../assets/tenant/organization-img.png") no-repeat center center;
}
.hero-left {
  margin-top: 12rem;
  h1 {
    font-family: Lato;
    font-style: normal;
    font-weight: 700;
    font-size: 56px;
    line-height: 125%;
    color: $whiteText;
    text-align: left;
  }
  p {
    font-family: Lato;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 150%;
    color: $whiteText;
    text-align: left;
  }
  .create-btn {
    text-align: left;
    margin-top: 40px;
    button {
      width: 183px;
      height: 56px;
      background: $primary;
      color: $whiteText;
      border-radius: 6px;
      font-family: Lato;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 125%;
      text-align: center;
      outline: 0;
      box-shadow: none;
    }
  }
}

@media only screen and (max-width: 599px) {
  .hero-section {
    padding-left: 0;
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.45) 66.15%,
        rgba(0, 0, 0, 0) 100%
      ),
      url("../assets/tenant/organization-mobile2.png") no-repeat center center;
    // height: fit-content;
    // height: 528px;
    // height: 400px;
  }
  .hero-left {
    margin-top: 7rem;
    h1 {
      font-size: 32px;
      text-align: left;
    }
    p {
      text-align: left;
    }
    .create-btn {
      text-align: left;
      button {
        height: 46px;
      }
    }
  }
}
// Hero section ends

// Card section starts
.cards-section {
  padding: 60px 77px;
}
.card-holder {
  padding: 20px;
  border: 0.5px solid #d4d2e3;
  border-radius: 10px;
  height: 350px;
  h4 {
    font-family: Lato;
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    line-height: 150%;
    text-align: center;
    text-transform: capitalize;
    color: $sheltaBlack;
    padding-top: 10px;
  }
  p {
    font-family: Lato;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 150%;
    text-align: center;
    color: $offGray;
    margin-bottom: 0;
    // padding-top: 10px;
  }
}

@media only screen and (max-width: 599px) {
  .cards-section {
    padding: 30px 0;
  }
  .card-holder {
    margin-bottom: 24px;
    height: fit-content;
    // border: none;
    h4 {
      font-size: 20px;
    }
    p {
      font-size: 16px;
    }
  }
}
// Card section ends
</style>
